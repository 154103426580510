<i18n>
{
  "de": {
    "pageTitle": "Liegenschaften hinzufügen",
    "returnButton": "Zurück"
  }
}
</i18n>

<template>
  <MainLayout :ready="portfolioReady" :portfolio="portfolio">
    <template #default>
      <h1>{{ portfolio.name }} - {{ $t('pageTitle') }}</h1>
      <ButtonWrapper>
        <div>
          <router-link class="button" :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id } }">{{
            $t('returnButton')
          }}</router-link>
        </div>
      </ButtonWrapper>
      <br />
      <br />
      <AddBuildingsToPortfolio :portfolio="portfolio" />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'

import AddBuildingsToPortfolio from '@/components/admin-portfolios/AddBuildingsToPortfolio.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'

export default {
  name: 'adminPortfolioAddBuildings',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
  ],

  components: {
    MainLayout,
    AddBuildingsToPortfolio,
    ButtonWrapper,
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>
